.about_container{
    display: flex;
    color: #000;
    width:98.5vw;
    /* height: 100vh; */
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
}
/* .img_container{
    display: flex;
    justify-content: flex-end;
    position: relative;
} */
/* .main_image{
    width:30vw;
    height: 80vh;
} */
.sub{
position: absolute;
bottom: 0;
left:0;

}
h2{
    color: black;
margin-top: 6rem;
margin-left: 2rem;
font-weight: bold;
}
.about_content {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-style: normal;
    
    line-height: 140.5%; /* Which gives 28.1px for a 20px font-size */
    letter-spacing: 0.2px;
}

.about_content ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about_content li {
    max-width: 628px;
    overflow-wrap: break-word;
}

.about_content span {
    font-weight: bold;
    display: block;
}

