.scroll-progress-container {
   position: absolute;
top:6rem;
    width: 100%;
    height: 8px;
    z-index: 100; /* adjust if necessary */
    background-color: transparent;
  }
  
  .scroll-progress-bar {
    height: 100%;
    background-color: #ffda2e; /* your desired color */
  }
  .scroll-progress-true {
    position: absolute;
 top:0;
     width: 100%;
     height: 8px;
     z-index: 100; /* adjust if necessary */
     background-color: transparent;
   }


   @media screen and (max-width: 767px) {
    .scroll-progress-container {
      position: absolute;
   top:4rem;
       width: 100%;
       height: 8px;
       z-index: 100; /* adjust if necessary */
       background-color: transparent;
     }
  }