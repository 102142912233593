.form_container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    gap:2rem
}
.form_container h2{
    align-self: center;
    padding: 3rem;
}
.form_container h2{
    color: #303234;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 20px; /* Base size for mobile */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.form_sub{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:20vw;
    gap:1rem
}

/* Tablet View: Devices with screen width from 768px to 1024px */
@media screen and (min-width: 768px) {
    .form_container h2 {
        font-size: 28px; /* Adjust font size for tablets */
    }
}

/* Desktop View: Devices with screen width from 1025px and above */
@media screen and (min-width: 1025px) {
    .form_container h2 {
        font-size: 36px; /* Original font size for desktop */
    }
}
