
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin; /* "auto" or "thin" or "none" */
  scrollbar-color: darkgrey lightgrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* This styles the scrollbar track (the background or "groove" in which the handle moves) */
::-webkit-scrollbar {
  width: 15px; /* width of the entire scrollbar */
}

/* This styles the scrollbar thumb/handle (the element that users interact with to scroll) */
::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates a "padding" effect inside the scrollbar */
  background-clip: padding-box; /* clips background color to create the effect of padding within the thumb */
}

/* This styles the track, or the area in which the thumb moves */
::-webkit-scrollbar-track {
  background: lightgrey; /* color of the track */
  border-radius: 6px; /* roundness of the track */
}
